<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>智慧水务</h1>
    </div>
    <div class="goods">
      <h3>产品与服务</h3>
      <div class="coreBusiness">
        <div
          class="box"
          v-for="item in businessList"
          :key="item.id"
          @click="go(item.id)"
        >
          <div class="box-title">{{ item.text }}</div>
          <!-- <div class="box-bottom">{{ item.english }}</div> -->
          <img :src="item.img" alt="" />
          <div class="more">
            了解更多
            <div class="jiao"></div>
            <div class="jiaos"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";

export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      creen: 0,
      active: 0,
      active1: 0,
      businessList: [
        {
          id: 1,
          text: "智慧供水",
          img: require("@/assets/assets/mimg1.png"),
          english: "WISDOM WATER SUPPLY",
        },
        {
          id: 2,
          text: "排污检测",
          img: require("@/assets/assets/mimg3.png"),
          english: "EMISSION MONITORING",
        },
        {
          id: 3,
          text: "污水处理",
          img: require("@/assets/assets/mimg4.png"),
          english: "SEWAGE TREATMENT",
        },
      ],
    };
  },

  methods: {
    go(item) {
      if (item == 1) {
        this.$router.push("/m_wisdomWater");
      }
      if (item == 2) {
        this.$router.push("/m_monitor");
      }
      if (item == 3) {
        this.$router.push("/m_sewage");
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/mbg1.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .goods {
    padding-top: 10px;
    h3 {
      text-align: center;
      // margin-bottom: 10px;
      letter-spacing: 5px;
      font-size: 18px;
      padding: 20px 0;
    }
    .coreBusiness {
      background-color: #fff;
      text-align: center;
      .box {
        padding: 0 10px;
        font-size: 16px;
        color: #1864e3;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          margin-bottom: 10px;
        }
        .box-title {
          position: absolute;
          top: 30%;
          left: 50%;
          font-size: 20px;
          color: #ffffff;
          transform: translate(-50%, -50%);
        }
        .box-bottom {
          width: 100%;
          position: absolute;
          top: 35%;
          left: 50%;
          color: #ffffff;
          transform: translate(-50%, -50%);
        }
        .more {
          width: 100px;
          height: 35px;
          border: 1px solid #fff;
          text-align: center;
          line-height: 35px;
          position: absolute;
          top: 70%;
          left: 50%;
          opacity: 1;
          color: #ffffff;
          padding-right: 5px;
          font-size: 14px;
          transform: translate(-50%, -50%);
          // .jiao {
          //   position: absolute;
          //   top: 35%;
          //   left: 85%;
          //   width: 0;
          //   height: 0;
          //   border: 6px solid transparent;
          //   border-left-color: white;
          // }
          // .active {
          //   position: absolute;
          //   top: 35%;
          //   left: 85%;
          //   width: 0;
          //   height: 0;
          //   border: 6px solid transparent;
          //   border-left-color: #039191;
          // }
        }
        .more:hover {
          background-color: #fff;
          color: black;
        }
      }
    }
  }
}
</style>
